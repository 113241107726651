import { cleanStore } from 'actions/global';
import { updateSiteState } from 'actions/uiState';
import { requestBanner } from 'actions/V3/adminParams';
import { requestV3Seacrh } from 'actions/V3/search';
import { AutoComplete, Icon, Input, Popover, Tooltip } from 'antd';
import classnames from 'classnames';
import Button from 'components/Custom/Button';
import TopNoteBar from 'components/TopNoteBar';
import i18n from 'configs/i18n';
import { t } from 'i18next';
import { debounce, trim } from 'lodash';
import qs from 'qs';
import * as React from 'react';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { getCookie, getLogoutUrl, isLogin } from 'utils/auth';
import { V2Url } from 'utils/host';
import { queryMerge } from 'utils/query';
import logoImage from '../../images/EN Wordmark Copy.png';
import NotificationBanner from '../Banner';
import './index.css';
import NIcon from './NotificationIcon';
import { P, State, StateProps } from './type';


const Option = AutoComplete.Option;
const OptGroup = AutoComplete.OptGroup;
const CheckBannerInterval = 10 * 60 * 1000

const searchTitle = {
  collections: t('search.collections'),
  locations: t('search.locations'),
  data_sources: t('search.data_sources'),
  workspaces: t('search.workspaces'),
  indicators: t('search.indicators')
};

class Header extends React.PureComponent<P, State> {
  state: State;

  debounceSearch: Function;

  clearCheckBannerInterval?: number

  constructor(props: P) {
    super(props);
    this.debounceSearch = debounce(this._search, 500);
    const {
      location: { search }
    } = props;
    const parsedQuery = qs.parse(search.slice(1));
    const query = typeof parsedQuery.q === 'string' ? parsedQuery.q : '';

    this.state = {
      search: query,
      layer: false,
      expand: false
    };
  }

  componentDidMount() {
    this.clearCheckBannerInterval = window.setInterval(
      () => this.props.dispatch(requestBanner()),
      CheckBannerInterval
    )
    this.props.dispatch(requestBanner())

  }
  componentWillUnmount() {
    if (this.clearCheckBannerInterval) {
      window.clearInterval(this.clearCheckBannerInterval)
      this.clearCheckBannerInterval = undefined
    }
  }

  gotoSearch = (type: string) => {
    const { search } = this.state;
    const { history } = this.props;
    history.push(`/search?q=${search}&type=${type}`);
  };

  changeLang = () => {
    const { language } = i18n;
    const newLang: 'en' | 'zh' = language.includes('zh') ? 'en' : 'zh';
    const {
      location: { search }
    } = this.props;
    const query = queryMerge({
      search,
      query: {
        lang: newLang
      }
    });

    window.location.search = query;
  };

  logInOrOut = () => {
    const { history, dispatch } = this.props;
    if (isLogin()) {
      // Retrieve the logout url if set
      const logoutUrl = getLogoutUrl();

      dispatch(cleanStore());

      if (logoutUrl) {
        window.location.href = logoutUrl;
        return;
      }

      // Redirect the user
      history.replace('/');
    } else {
      dispatch(
        updateSiteState({
          redirectURL: `${window.location.pathname}${window.location.search}`
        })
      );
      history.replace('/user/sign_in');
    }
  };

  downloadButton = () => {
    const logined = isLogin();
    const { hasPro } = this.props;

    return (
      logined &&
      (!hasPro ? (
        <Tooltip title={`${t('Basic')} ${t('can not use')} ${t('download')}`}>
          <i className='material-icons md-18 icon_disabled'>cloud_download</i>
        </Tooltip>
      ) : (
        <Link to='/download' title={t('header.download')}>
          <i className='material-icons md-18'>cloud_download</i>
        </Link>
      ))
    );
  };

  billingButton = () => {
    const { org } = this.props;
    const logined = isLogin();
    return (
      logined &&
      org && (
        <Link to={`/billing/${org.id}/month`} title={t('header.billing')}>
          <i className='iconfont icon-quote' />
        </Link>
      )
    );
  };

  notification = () => {
    const logined = isLogin();
    const { hasPro } = this.props;
    return (
      logined &&
      (!hasPro ? (
        <Tooltip
          title={`${t('Basic')} ${t('can not use')} ${t(
            'navbar.Notification'
          )}`}
        >
          <i className='material-icons md-18 icon_disabled'>notifications</i>
        </Tooltip>
      ) : (
        <NIcon />
      ))
    );
  };
  renderTitle = (title: string) => {
    const { search } = this.state;
    return (
      <span className='header-search-title'>
        <span className='header-search-text'>
          {t('search.search for')} <span className='accent_text'>{search}</span>
          {t('search.related', { title: searchTitle[title] })}
        </span>

        <a
          className='header-search-more'
          onClick={() => this.gotoSearch(title)}
        >
          {t('search.more')}
        </a>
      </span>
    );
  };

  options = () => {
    const { search } = this.props;
    const { search: s } = this.state;
    if (!search || !s) {
      return [];
    }
    const data = Object.keys(search)
      .map((key) => ({
        title: key,
        children: search[key]
      }))
      .filter((v) => v.children.length);
    const Opt: any = Option;

    return data.map((group) => (
      <OptGroup key={group.title} label={this.renderTitle(group.title)}>
        {group.children.map((opt) => (
          <Opt
            text={s}
            key={`${opt.title}-${opt.id}`}
            value={`${group.title}-${opt.id}-${opt.workspace_id}-${opt.name}`}
          >
            <Highlighter
              searchWords={[s]}
              autoEscape={true}
              textToHighlight={
                group.title !== 'data_sources'
                  ? opt.name || ''
                  : `${opt.name ? opt.name + ' - ' : ''}${opt.identifier}`
              }
            />
          </Opt>
        ))}
      </OptGroup>
    ));
  };

  toggleLayer = () => {
    const { layer } = this.state;
    const body = document.querySelector('body');
    if (body!.classList.contains('is-locked')) {
      body!.classList.remove('is-locked');
    } else {
      body!.classList.add('is-locked');
    }
    this.setState({ layer: !layer, expand: false });
  };

  navBack = () => {
    const { history, originURL } = this.props;
    history.push(originURL || '/public');
  };

  renderMenu = () => {
    const { expand } = this.state;
    const logined = isLogin();
    return (
      <div className='header-layer-content'>
        <span className='header-layer-traingle' />
        <ul className='header-layer-list'>
          <li>
            <a href='https://qlear.io/web/knowledge'>{t('header.knowledge')}</a>
          </li>
          <li>
            <Link to='/changelog'>{t('header.whatsNew')}</Link>
          </li>
          <li>
            <a href='https://qlear.io/web/contact'>{t('header.contactUs')}</a>
          </li>
          <li>
            <a href={V2Url()}>{t('header.go to v2')}</a>
          </li>
        </ul>

        <ul className='header-layer-list header-layer--secend_list'>
          {logined && (
            <li>
              <Link to='/profile'>{t('header.profile')}</Link>
            </li>
          )}
          {logined && (
            <li>
              <Link to='/password'>{t('header.password')}</Link>
            </li>
          )}
          <li>
            <a onClick={this.changeLang}>{t('header.lang')}</a>
          </li>
        </ul>

        <ul className='header-layer-list'>
          <li
            className='header-layer-more'
            onClick={() => this.setState({ expand: !expand })}
          >
            <span>{expand ? t('header.less') : t('header.more')}</span>
            <a className='material-icons md-18'>
              {expand ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            </a>
          </li>
        </ul>
        {expand && (
          <ul className='header-layer-list header-layer--expand_list'>
            <li>
              <a href='https://qlear.io/web/about'>{t('header.about')}</a>
            </li>
            <li>
              <a href='https://qlear.io/web/product'>{t('header.product')}</a>
            </li>
            <li>
              <a href='https://qlear.io/web/plans'>{t('header.plans')}</a>
            </li>
            <li>
              <a href='https://qlear.io/web/partners'>{t('header.partners')}</a>
            </li>
            {/* <li>
              <a href='https://blog.qlear.io/'>{t('header.blog')}</a>
            </li> */}
            <li>
              <a href='https://angel.co/qlear'>{t('header.careers')}</a>
            </li>
            <li>
              <a href='https://qlear.io/web/termsService'>
                {t('header.terms')}
              </a>
            </li>
          </ul>
        )}
        <Button
          customType='dark'
          className='header-layer-logout'
          onClick={this.logInOrOut}
        >
          {logined ? t('header.Sign Out') : t('header.Sign In or Sign Up')}
        </Button>
      </div>
    );
  };

  renderWorkspaceLocationInformation = () => {
    const { locationInformation } = this.props;
    return (
      <div className='header-layer-content'>
        <div dangerouslySetInnerHTML={{ __html: locationInformation }} />
      </div>
    );
  };

  _search = (text: string) => {
    const { dispatch } = this.props;
    text = text.trim();
    if (text) {
      dispatch(
        requestV3Seacrh({
          q: text,
          show: false
        })
      );
    }
  };

  search = (text: string) => {
    this.setState({ search: text });
    this.debounceSearch(text);
  };

  searchJump = (v) => {
    const [type, id, workspace] = v.split('-');
    const { history } = this.props;

    switch (type) {
      case 'collections':
        return history.push(`/workspaces/${workspace}/collections/${id}`);
      case 'locations':
        return history.push(`/workspaces/${workspace}/locations/${id}`);
      case 'data_sources':
        return history.push(`/workspaces/${workspace}/datasources/${id}`);
      case 'workspaces':
        return history.push(`/workspaces/${id}`);
      case 'indicators':
        return history.push(`/workspaces/${workspace}/indicators/${id}`);
      default:
    }
  };

  closeNoteBar = () => {
    const { dispatch } = this.props;
    dispatch(updateSiteState({ showNoteBar: false }));
  };

  render() {
    const {
      kiosk,
      config,
      showNoteBar,
      QRCode,
      hasPro,
      org,
      originURL,
      banner,
      showQrCode,
      locationInformation
    } = this.props;
    const { layer, expand } = this.state;
    const cookie = getCookie();
    const logined = isLogin();
    const showBar = !kiosk && showNoteBar;
    const showBanner = !kiosk && banner && banner.show_technical_difficulties_banner
    const logo = config ? config.logo : logoImage;

    return (
      <div className='header-outter-wrap'>
        <div
          className={classnames('header-placeholder', {
            'has-note-bar': showBar
          })}
        />
        <div className='header-container'>
          {showBar && <TopNoteBar onClose={this.closeNoteBar} />}
          <div className={classnames('header-content', { 'is-kiosk': kiosk })}>
            <div className='header-desktop'>
              <div className='logo-wrap'>
                {kiosk && originURL && (
                  <div className='nav-back' onClick={this.navBack}>
                    <Icon type='arrow-left' />
                  </div>
                )}
                <Link to='/'>
                  <img className='header-logo' src={logo} alt='QLEAR' />
                </Link>
              </div>

              {logined && !kiosk && (
                <div className='header-center'>
                  <AutoComplete
                    // value={this.state.search}
                    dropdownMatchSelectWidth={true}
                    dropdownClassName='header-search-dropdown'
                    className='header-search'
                    getPopupContainer={(trigger) => trigger!.parentElement!}
                    onSelect={this.searchJump}
                    onSearch={this.search}
                    dataSource={this.options()}
                    placeholder={t('search.search')}
                    optionLabelProp='text'
                  >
                    <Input
                      prefix={
                        <Icon
                          type='search'
                          style={{
                            color: 'white',
                            fontWeight: 'bolder',
                            marginLeft: 10
                          }}
                        />
                      }
                    />
                  </AutoComplete>
                </div>
              )}

              <div
                className={classnames('header-user', { 'is-login': logined })}
              >
                {kiosk && locationInformation && (
                  <Popover
                    overlayClassName='header-notification-overlay location-information'
                    content={this.renderWorkspaceLocationInformation()}
                    getPopupContainer={(trigger) => trigger!.parentElement!}
                    placement='bottomRight'
                    trigger='click'
                    title={
                      <div className='noti-title'>
                        <b>{t('Workspace Locations Informations')}</b>
                      </div>
                    }
                  >
                    <Icon type='environment' />
                  </Popover>
                )}
                {kiosk && QRCode && showQrCode && (
                  <Popover
                    overlayClassName='header-notification-overlay'
                    content={<img src={QRCode} alt='QR code' width='100%' />}
                    getPopupContainer={(trigger) => trigger!.parentElement!}
                    placement='bottom'
                    trigger='click'
                  >
                    <Icon type='qrcode' />
                  </Popover>
                )}
                {this.billingButton()}
                {this.downloadButton()}
                {this.notification()}
                {logined ? (
                  <Popover
                    overlayClassName='header-notification-overlay is-menu'
                    content={this.renderMenu()}
                    getPopupContainer={(trigger) => trigger!.parentElement!}
                    placement='bottom'
                    trigger='click'
                  >
                    <span>{trim(cookie!.user_name || '').split(' ')[0]}</span>
                  </Popover>
                ) : (
                  <a onClick={this.logInOrOut}>{t('user.Sign In')}</a>
                )}
              </div>
            </div>

            <div className='header-mobile'>
              <div className='header-title logo-wrap'>
                {kiosk && originURL && (
                  <div className='nav-back' onClick={this.navBack}>
                    <Icon type='arrow-left' />
                  </div>
                )}
                <Link to='/'>
                  <img className='header-logo' src={logo} alt='QLEAR' />
                </Link>
              </div>

              {logined && !kiosk && (
                <div className='header-center'>
                  <AutoComplete
                    // value={this.state.search}
                    dropdownMatchSelectWidth={true}
                    dropdownClassName='header-search-dropdown'
                    className='header-search'
                    getPopupContainer={(trigger) => trigger!.parentElement!}
                    onSelect={this.searchJump}
                    onSearch={this.search}
                    dataSource={this.options()}
                    placeholder={t('search.search')}
                    optionLabelProp='text'
                  >
                    <Input
                      prefix={
                        <Icon
                          type='search'
                          style={{
                            color: 'white',
                            fontWeight: 'bolder',
                            marginLeft: 10
                          }}
                        />
                      }
                    />
                  </AutoComplete>
                </div>
              )}

              <div className='header-icon'>
                {logined ? (
                  <i
                    className='material-icons md-24'
                    onClick={this.toggleLayer}
                  >
                    more_horiz
                  </i>
                ) : (
                  <a className='header-mobile-login' onClick={this.logInOrOut}>
                    {t('user.Sign In')}
                  </a>
                )}
              </div>
            </div>

            <div
              className={classnames('header-layer', { 'is-visiable': layer })}
            >
              <div className='header-layer-content'>
                <span className='header-layer-traingle' />
                <ul className='header-layer-list'>
                  {hasPro && (
                    <>
                      <li>
                        <Link to='/notification' onClick={this.toggleLayer}>
                          {t('navbar.Notification')}
                        </Link>
                      </li>
                      <li>
                        <Link to='/download' onClick={this.toggleLayer}>
                          {t('header.download')}
                        </Link>
                      </li>
                      {org && (
                        <li>
                          <Link to={`/billing/${org.id}/month`}>
                            {t('header.billing')}
                          </Link>
                        </li>
                      )}
                    </>
                  )}

                  <li>
                    <a href='https://qlear.io/web/knowledge'>
                      {t('header.knowledge')}
                    </a>
                  </li>
                  <li>
                    <Link to='/changelog' onClick={this.toggleLayer}>
                      {t('header.whatsNew')}
                    </Link>
                  </li>
                  <li>
                    <a href='https://qlear.io/web/contact'>
                      {t('header.contactUs')}
                    </a>
                  </li>
                  <li>
                    <a href='https://v2.qlear.io'>{t('header.go to v2')}</a>
                  </li>
                </ul>

                <ul className='header-layer-list header-layer--secend_list'>
                  {logined && (
                    <li>
                      <Link to='/profile' onClick={this.toggleLayer}>
                        {t('header.profile')}
                      </Link>
                    </li>
                  )}
                  {logined && (
                    <li>
                      <Link to='/password' onClick={this.toggleLayer}>
                        {t('header.password')}
                      </Link>
                    </li>
                  )}
                  <li>
                    <a onClick={this.changeLang}>{t('header.lang')}</a>
                  </li>
                </ul>

                <ul className='header-layer-list'>
                  <li
                    className='header-layer-more'
                    onClick={() => this.setState({ expand: !expand })}
                  >
                    <span>{expand ? t('header.less') : t('header.more')}</span>
                    <a className='material-icons md-18'>
                      {expand ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                    </a>
                  </li>
                </ul>
                {expand && (
                  <ul className='header-layer-list header-layer--expand_list'>
                    <li>
                      <a href='https://qlear.io/web/about'>
                        {t('header.about')}
                      </a>
                    </li>
                    <li>
                      <a href='https://qlear.io/web/product'>
                        {t('header.product')}
                      </a>
                    </li>
                    <li>
                      <a href='https://qlear.io/web/plans'>
                        {t('header.plans')}
                      </a>
                    </li>
                    <li>
                      <a href='https://qlear.io/web/partners'>
                        {t('header.partners')}
                      </a>
                    </li>
                    <li>
                      <a href='https://angel.co/qlear'>{t('header.careers')}</a>
                    </li>
                    <li>
                      <a href='https://qlear.io/web/termsService'>
                        {t('header.terms')}
                      </a>
                    </li>
                  </ul>
                )}
                <Button
                  customType='dark'
                  className='header-layer-logout'
                  onClick={this.logInOrOut}
                >
                  {logined
                    ? t('header.Sign Out')
                    : t('header.Sign In or Sign Up')}
                </Button>
              </div>
              <div className='header-layer-close'>
                <span onClick={this.toggleLayer}>{t('Close')}</span>
              </div>
            </div>
          </div>
        </div>
        {showBanner && <NotificationBanner />}
      </div>
    );
  }
}

const mapStateToProps: MapState<StateProps> = ({
  V2: { notifications, userShip },
  V3: {
    search,
    adminParams,
    config,
    org,
    kiosks: { detail }
  },
  uiState: {
    siteState: { workspaceID, originURL, showNoteBar }
  }
}) => {
  const hasPro = userShip.data.some((w) => w.pro);
  return {
    workspaceID,
    originURL,
    search: search.data,
    config: config.data,
    showNoteBar,
    org: org.detail,
    notifications: notifications.data,
    notificationMeta: notifications.meta,
    hasPro,
    QRCode: detail?.qr_code,
    showQrCode: detail?.show_qr_code,
    kioskInformation: detail?.kiosk_information,
    locationInformation: detail?.location_information,
    banner: adminParams.banner
  };
};

export default withRouter(connect(mapStateToProps)(Header));
