import { Action, ActionCreator } from 'redux'

export const REQUEST_KIOSK_V3 = 'REQUEST_KIOSK_V3'
export const GET_KIOSK_V3 = 'GET_KIOSK_V3'

export const REQUEST_KIOSK_EDIT_INFO_V3 = 'REQUEST_KIOSK_EDIT_INFO_V3'
export const GET_KIOSK_EDIT_INFO_V3 = 'GET_KIOSK_EDIT_INFO_V3'

export const REQUEST_KIOSK_EDIT_V3 = 'REQUEST_KIOSK_EDIT_V3'
export const GET_KIOSK_EDIT_V3 = 'GET_KIOSK_EDIT_V3'

export const REQUEST_KIOSK_DELETE_V3 = 'REQUEST_KIOSK_DELETE_V3'
export const GET_KIOSK_DELETE_V3 = 'GET_KIOSK_DELETE_V3'

export const REQUEST_KIOSK_DETAIL_V3 = 'REQUEST_KIOSK_DETAIL_V3'
export const GET_KIOSK_DETAIL_V3 = 'GET_KIOSK_DETAIL_V3'

export const REQUEST_KIOSK_NEW_V3 = 'REQUEST_KIOSK_NEW_V3'
export const GET_KIOSK_NEW_V3 = 'GET_KIOSK_NEW_V3'

export const CLEAN_KIOSK_V3 = 'CLEAN_KIOSK_V3'

type ChannelEditInfo = {
  available_units: string[],
  channel: string,
  default_unit: string,
  formula?: string,
  hidden: boolean,
  identifier: string,
  indicator_id: number,
  name: string,
  type: DataChannelType,
  unit?: string
}

// Kioks 页面请求Kiosk 详情返回的状态

export type Reading = {
  data_channel: string,
  name: string,
  type: DataChannelType,
  default_unit: string,
  unit: string,
  value?: number,
  level?: string,
  use_aqi: boolean,
  formula?: string,
  channel: string,
  precision: number,
  time?: string
}

// export type Collection = {
//   hidden: boolean;
//   id: number;
//   name: string;
//   code: string;
//   bto: string;
//   status?: string;
//   stale: boolean;
//   last_reading_time?: string;
//   primary_data_channel: string;
//   category_name: "location" | "station";
//   readings: Reading[];
//   data_channels: any;
// };

export interface KioskLock {
  background_image?: string;
  city_id?: number;
  city_name?: string;
  collection_id?: number;
  collection_name?: string;
  id: number | string;
  location_id: number;
  location_name: string;
  logo_image?: string;
  name: string;
  time_zone?: string;
  workspace_id: number;
  workspace_name: string;
}

type DataPanelPosition = 'left' | 'right';

interface KioskBaseInfo extends KioskLock {
  background_full_screen: boolean;
  background_video?: string;
  data_panel_opacity?: number;
  data_panel_position: DataPanelPosition;
  enable_background_video: boolean;
  font?: string;
  font_color?: string;
  hide_location_average: boolean;
  locked: boolean;
  outdoor_flag: boolean;
  password?: string;
  theme_color?: string;
  use_aqi: boolean;
  app_background_image?: string;
  app_logo_image?: string;
  screen_type: string;
  template_key: string;
  template_config: {
    defences?: {
      name: string,
      value: string
    }[],
    notification_user_id: number,
    advices: {
      offline: string,
      configs: Record<string, any>
    }
  };
  name: string;
  show_qr_code: boolean;
  show_health_rating: boolean;
  kiosk_information: string;
}

export interface KioskListItem extends KioskBaseInfo {
  primary_data_channel?: string;
  display_data_channels?: string;
}

export interface CollapsePanelinfo {
  code: string;
  data_channels: ChannelEditInfo;
  hidden: boolean;
  id: number;
  name: string;
  primary_data_channel?: string;
}

export interface KioskEditInfo extends KioskBaseInfo {
  average: {
    data_channels: ChannelEditInfo[],
    hidden: false,
    primary_data_channel?: string,
    position: string[]
  };
  collections: {
    data_channels: ChannelEditInfo[],
    code: string,
    hidden: boolean,
    id: number,
    name: string,
    primary_data_channel?: string,
    position: string[]
  }[];
}
export interface KioskCollection {
  id: number,
  code: string,
  name: string,
  status?: string,
  stale: boolean,
  last_reading_time?: string,
  primary_data_channel?: string,
  bto?: string,
  pr?: string,
  readings: Reading[]
  covid_index_data: CovidIndexData
}
export type KioskAverage = Omit<KioskCollection, 'id' | 'code' | 'name'>
export interface KioskDetail extends KioskBaseInfo {
  readings: any;
  covid_index_supplementary_data?: any;
  qr_code: string;
  show_qr_code: boolean;
  kiosk_information: string;
  location_information: string;
  historical_data_limit: string;
  pro: boolean;
  defences: {
    name: string,
    value: string
  }[];
  notifications: {
    reading_time: string,
    created_at: string,
    content: string,
    advice: string
  }[];
  average?: KioskAverage;
  collection?: Omit<KioskCollection, 'covid_index_data'>;
  collections: KioskCollection[];
  data_channels: {
    name: string,
    channel: string
  }[];
  outdoor?: {
    id: number,
    name: string,
    readings: Reading[]
  };
}

export type KioskEdit = {
  id: number | string,
  name: string,
  location_name: string,
  location_id: number,
  locked: boolean
}

export type KioskDelete = {
  id: number | string,
  location_id: number
}

export interface RequestKiosksAction extends Action<typeof REQUEST_KIOSK_V3> {
  args: any;
}

export interface RequestKioskEditAction extends Action<typeof REQUEST_KIOSK_EDIT_V3> {
  args: any;
}

export interface RequestKioskDeleteAction extends Action<typeof REQUEST_KIOSK_DELETE_V3> {
  args: any;
}

export interface RequestKioskDetailAction extends Action<typeof REQUEST_KIOSK_DETAIL_V3> {
  args: any;
}

export interface RequestKioskNewAction extends Action<typeof REQUEST_KIOSK_NEW_V3> {
  args: any;
}

export interface RequestKioskEditInfoAction extends Action<typeof REQUEST_KIOSK_EDIT_INFO_V3> {
  args: any;
}

export interface CleanKioskAction extends Action<typeof CLEAN_KIOSK_V3> {
  args: any;
}

interface GetKiosksAction extends Action<typeof GET_KIOSK_V3> {
  kiosks: {
    id: number | string,
    name: string,
    kiosk_list: KioskListItem[]
  }[];
  meta: Meta;
  query: {};
}

interface GetKioskEditAction extends Action<typeof GET_KIOSK_EDIT_V3> {
  kioskEdit: KioskEdit;
}

interface GetKioskDeleteAction extends Action<typeof GET_KIOSK_DELETE_V3> {
  kioskDelete: KioskDelete;
}

interface GetKioskDetailAction extends Action<typeof GET_KIOSK_DETAIL_V3> {
  kioskDetail?: KioskDetail;
  kioskLock?: KioskLock;
}

interface GetKioskNewAction extends Action<typeof GET_KIOSK_NEW_V3> {
  kioskNew: KioskEditInfo;
}

interface GetKioskEditInfoAction extends Action<typeof GET_KIOSK_EDIT_INFO_V3> {
  KioskEditInfo: KioskEditInfo;
}

export type KiosksAction =
  | GetKiosksAction
  | GetKioskEditAction
  | GetKioskDetailAction
  | GetKioskNewAction
  | GetKioskEditInfoAction
  | CleanKioskAction
  | GetKioskDeleteAction

export const cleanKiosk: ActionCreator<CleanKioskAction> = args => ({
  type: CLEAN_KIOSK_V3,
  args
})
export const requestKiosks: ActionCreator<RequestKiosksAction> = args => ({
  type: REQUEST_KIOSK_V3,
  args
})

export const requestKioskEdit: ActionCreator<RequestKioskEditAction> = args => ({
  type: REQUEST_KIOSK_EDIT_V3,
  args
})

export const requestKioskDelete: ActionCreator<RequestKioskDeleteAction> = args => ({
  type: REQUEST_KIOSK_DELETE_V3,
  args
})

export const requestKioskDetail: ActionCreator<RequestKioskDetailAction> = args => ({
  type: REQUEST_KIOSK_DETAIL_V3,
  args
})

export const requestKioskNew: ActionCreator<RequestKioskNewAction> = args => ({
  type: REQUEST_KIOSK_NEW_V3,
  args
})

export const requestKioskEditInfo: ActionCreator<RequestKioskEditInfoAction> = args => ({
  type: REQUEST_KIOSK_EDIT_INFO_V3,
  args
})

export const getKiosks: ActionCreator<GetKiosksAction> = (kiosks, meta, query) => ({
  type: GET_KIOSK_V3,
  kiosks,
  meta,
  query
})

export const getKioskEdit: ActionCreator<GetKioskEditAction> = (kioskEdit: KioskEdit) => ({
  type: GET_KIOSK_EDIT_V3,
  kioskEdit
})

export const getKioskNew: ActionCreator<GetKioskNewAction> = (kioskNew: KioskEditInfo) => ({
  type: GET_KIOSK_NEW_V3,
  kioskNew
})

export const getKioskDelete: ActionCreator<GetKioskDeleteAction> = (kioskDelete: KioskDelete) => ({
  type: GET_KIOSK_DELETE_V3,
  kioskDelete
})

export const getKioskDetail: ActionCreator<GetKioskDetailAction> = (kioskDetail, kioskLock) => ({
  type: GET_KIOSK_DETAIL_V3,
  kioskDetail,
  kioskLock
})

export const getKioskEditInfo: ActionCreator<GetKioskEditInfoAction> = (KioskEditInfo: KioskEditInfo) => ({
  type: GET_KIOSK_EDIT_INFO_V3,
  KioskEditInfo
})

export type CovidIndexData = {
  covid_index: number,
  optimization_index: number,
  term_ach: number,
  term_co2: number,
  term_immunity: number,
  term_pm25: number,
  term_rh: number
}
